import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { supabase } from "../configurations/supabaseConfig";

const description = `Digital Marketing துறையில் AI எவ்வாறு பயன்படுத்தப்படுகிறது என அறிந்து கொள்ள ஆசைப்படுகிறீர்களா? 🤔

ஆம் எனில் ✨ TrendsEd Institute-யினால் எதிர்வரும் September 28 🗓️ ஆம் திகதி நடைபெறவுள்ள Digital Marketing  தொடர்பான Online Seminar 💻 இல் கலந்து கொள்வதன் மூலம், உங்களின் Digital Marketing திறன்களை AI Marketing Tools  உதவியுடன் மேம்படுத்திக் கொள்ளுங்கள். 🌟

🆓 அனுமதி இலவசம்!
📅 திகதி: 28.09.2024
🕖 நேரம்: மாலை 7:30 முதல் 9:30 வரை
📍 செயலி: Google Meet
 
📌 விண்ணப்ப இறுதி திகதி: 27.09.2024 

இந்த Webinar Online இல் நடைபெறும் அதற்குரிய இணைப்பு உங்களுக்கு Email Or WhatsApp ஊடாக கிடைக்க பெறும்.

மேலதிக விபரங்களிற்கு 0707794432 எனும் எண்ணை தொடர்பு கொள்ளவும்.`;

const formData = {
  formTitle: "Digital Marketing-இல் AI! ஒரு புதிய அனுபவம்!",
  formDescription:
    "Digital Marketing மூலம் AI எவ்வாறு பயன்படுத்தப்படுகிறது என்பதைச் சொல்லி கொடுக்கவிருக்கிறோம்!",
  date: "28.09.2024",
  time: "7:30 PM to 9:30 PM",
  location: "Google Meet",
  registrationDeadline: "27.09.2024",
  fields: [
    {
      label: "Full Name",
      type: "text",
      required: true,
      name: "full_name",
    },
    {
      label: "Phone Number",
      type: "text",
      required: true,
      name: "phone_number",
    },
    {
      label: "WhatsApp Number",
      type: "text",
      required: true,
      name: "whatsapp_number",
    },
    {
      label: "Email Address",
      type: "email",
      required: true,
      name: "email_address",
    },
    {
      label: "Your Current Status",
      type: "radio",
      required: true,
      name: "current_status",
      options: [
        "School Student",
        "O/L Completed",
        "A/L Completed",
        "University Student",
        "Working",
      ],
    },
    {
      label: "Name of your School/University/Working place",
      type: "text",
      required: true,
      name: "institution_name",
    },
    {
      label: "Gender",
      type: "radio",
      required: true,
      name: "gender",
      options: ["Male", "Female", "Other"],
    },
    {
      label: "Your District",
      type: "dropdown",
      required: true,
      name: "district",
      options: [
        "Ampara",
        "Anuradhapura",
        "Badulla",
        "Batticaloa",
        "Colombo",
        "Galle",
        "Gampaha",
        "Hambantota",
        "Jaffna",
        "Kalutara",
        "Kandy",
        "Kegalle",
        "Kilinochchi",
        "Kurunegala",
        "Mannar",
        "Matale",
        "Matara",
        "Monaragala",
        "Mullaitivu",
        "Nuwara Eliya",
        "Polonnaruwa",
        "Puttalam",
        "Ratnapura",
        "Trincomalee",
        "Vavuniya",
      ],
    },
    {
      label: "What is your level of experience in digital marketing?",
      type: "radio",
      required: true,
      name: "experience_level",
      options: ["Beginner", "Intermediate", "Expert"],
    },
    {
      label: "Would You Be Interested in Exploring a Digital Marketing Course?",
      type: "radio",
      required: true,
      name: "interested_in_course",
      options: ["Yes", "No", "Maybe"],
    },
    {
      label: "How do you know about this Webinar?",
      type: "radio",
      required: true,
      name: "source_of_information",
      options: ["Facebook", "Instagram", "WhatsApp", "From friend"],
    },
  ],
  submitButton: {
    text: "Submit",
  },
};

export default function DigitalMarketing() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: formData.fields.reduce((acc, field) => {
      acc[field.name] = "";
      return acc;
    }, {}),
    validationSchema: Yup.object(
      formData.fields.reduce((acc, field) => {
        if (field.required) {
          acc[field.name] = Yup.string().required(`${field.label} is required`);
        }
        if (field.name === "phone_number") {
          acc[field.name] = Yup.string().matches(
            /^\d{10}$/,
            "Phone number must be 10 digits"
          );
        }

        if (field.name === "email_address") {
          acc[field.name] = Yup.string().email("Invalid email address");
        }
        return acc;
      }, {})
    ),
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data, error } = await supabase
          .from("digital_marketing_registrations")
          .insert([values]);
        if (error) {
          console.error("Error inserting data:", error);
          toast.error("Failed to submit the form. Please try again later.");
        } else {
          resetForm();
          setIsSubmitted(true);
          toast.success("Form has been successfully submitted!");
        }
      } catch (error) {
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred.");
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mb: 2,
          width: "60%",
          mt: 2,
          "@media (max-width: 600px)": {
            width: "90%",
          },
        }}
      >
        {!isSubmitted ? (
          <>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                mb: 1,
                borderRadius: "10px",
              }}
            >
              <img
                src="/images/cover.png"
                alt="header image"
                className="responsive-image"
                style={{ width: "100%" }}
              />

              <Box m={2}>
                <Typography variant="h5">{formData.formTitle}</Typography>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/\n/g, "<br />"),
                  }}
                />
                <Typography variant="body2">
                  <strong>Date:</strong> {formData.date} <br />
                  <strong>Time:</strong> {formData.time} <br />
                  <strong>Location:</strong> {formData.location}
                </Typography>
              </Box>
            </Card>

            <form onSubmit={formik.handleSubmit}>
              <Box m={2}>
                {formData.fields.map((field, index) => (
                  <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }} key={index}>
                    <Typography variant="h6">
                      {field.label} {field.required && <span>*</span>}
                    </Typography>
                    {field.type === "text" || field.type === "email" ? (
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Answer Here"
                        name={field.name}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                        helperText={
                          formik.touched[field.name] &&
                          formik.errors[field.name]
                        }
                      />
                    ) : field.type === "radio" ? (
                      <FormControl
                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                      >
                        <RadioGroup
                          name={field.name}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                        >
                          {field.options.map((option, i) => (
                            <FormControlLabel
                              key={i}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                        <FormHelperText>
                          {formik.touched[field.name] &&
                            formik.errors[field.name]}
                        </FormHelperText>
                      </FormControl>
                    ) : field.type === "dropdown" ? (
                      <FormControl
                        fullWidth
                        error={
                          formik.touched[field.name] &&
                          Boolean(formik.errors[field.name])
                        }
                      >
                        <Select
                          name={field.name}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          displayEmpty
                          variant="standard"
                        >
                          <MenuItem value="">
                            <em>Select {field.label}</em>
                          </MenuItem>
                          {field.options.map((option, i) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {formik.touched[field.name] &&
                            formik.errors[field.name]}
                        </FormHelperText>
                      </FormControl>
                    ) : null}
                  </Card>
                ))}
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" type="submit">
                    {formData.submitButton.text}
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        ) : (
          <>
            <Card
              sx={{
                p: 4,
                mb: 3,
                borderRadius: "15px",
                height: "auto",
                backgroundColor: "#f5f5f5",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h4" sx={{ mb: 2, color: "#1976d2" }}>
                Your Form Submitted Successfully
              </Typography>
              <Typography variant="body1" sx={{ color: "#555" }}>
                இந்த Webinar தொடர்பான விடயங்கள் மற்றும் இந்த Webinar இல் இணைந்து
                கொள்வதற்கான Google meet Link இனை பெற்றுக்கொள்வதற்கும் கீழே உள்ள
                WhatsApp Group invite Link இனை கிளிக் செய்து TrendsED இன்
                Official WhatsApp Group இல் இணைந்து கொள்ளுங்கள். <br /> Follow
                this link to join TrendsED React WhatsApp group: <br />
                <a
                  href="https://chat.whatsapp.com/HaDmfEPeKY51PExiIJSJXI"
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  https://chat.whatsapp.com/HaDmfEPeKY51PExiIJSJXI
                </a>
              </Typography>
              <br />
              <br />
              <br />
              <a
                style={{ textDecoration: "underline", color: "blue" }}
                onClick={() => setIsSubmitted(false)}
              >
                Submit another response
              </a>
            </Card>
          </>
        )}
      </Box>
    </Box>
  );
}
