import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { supabase } from "../configurations/supabaseConfig";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export default function CourseForm() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    try {
      await addApplicantData(values);
      resetForm();
      setIsSubmitted(true);
      toast.success("Form has been successfully submitted!");
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const newApplicant = useFormik({
    initialValues: {
      name: "",
      whatsAppNumber: "",
      localNumber: "",
      email: "",
      professionName: "",
      status: "",
      laptop: "",
      gender: "",
      knowHtml: "",
      gotToKnowFrom: "",
      ageLimit: "",
      district: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      professionName: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      knowHtml: Yup.string().required("Required"),
      gotToKnowFrom: Yup.string().required("Required"),
      ageLimit: Yup.string().required("Required"),
      laptop: Yup.string().required("Required"),
      district: Yup.string().required("Required"),
      localNumber: Yup.string().required("Required"),
      whatsAppNumber: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit,
  });

  const addApplicantData = async (values) => {
    try {
      const { data, error } = await supabase
        .from("react_webinar")
        .insert([values])
        .select("*");
      if (data) {
        console.log("Data added successfully:", data);
        toast.info("Applicant has been successfully created!");
      }
      if (error) {
        throw error;
      }
    } catch (error) {
      throw new Error("Error inserting data:", error.message);
    }
  };

  const districts = [
    "Ampara",
    "Anuradhapura",
    "Badulla",
    "Batticaloa",
    "Colombo",
    "Galle",
    "Gampaha",
    "Hambantota",
    "Jaffna",
    "Kalutara",
    "Kandy",
    "Kegalle",
    "Kilinochchi",
    "Kurunegala",
    "Mannar",
    "Matale",
    "Matara",
    "Monaragala",
    "Mullaitivu",
    "Nuwara Eliya",
    "Polonnaruwa",
    "Puttalam",
    "Ratnapura",
    "Trincomalee",
    "Vavuniya",
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mb: 2,
          width: "60%",
          mt: 2,
          "@media (max-width: 600px)": {
            width: "90%",
          },
        }}
      >
        {!isSubmitted ? (
          <>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                mb: 1,
                borderRadius: "10px",
              }}
            >
              <img
                src="/images/react.jpg"
                alt="header image"
                className="responsive-image"
                style={{ width: "100%" }}
              />

              <Box m={2}>
                <h3>React Basics</h3>
                <p>
                  நீங்களும் இணையத்தள வடிவமைப்பில் (Web design) ஆர்வம் கொண்டவரா?
                  💻✨
                </p>
                <p>
                  React இன் அடிப்படைகள் மற்றும் அதன் பயன்பாடுகள் பற்றி அறிந்து
                  கொள்ளவும். அத்துடன் React இன் வேலைவாய்ப்புகள் மற்றும் அதன்
                  தொழிநுட்ப பயன்பாடுகளையும் அறிந்து கொள்வதற்காகவும் TrendsED
                  இனால் வடிவமைக்கப்பட்டு எதிர்வரும் July 20 ஆம் திகதி
                  நடைபெறவுள்ள "React Basics" Webinar இல் கலந்துகொண்டு Web design
                  துறையில் பயணத்தைத் தொடங்க தயாராக்குங்கள்! 🚀🌟
                </p>
                <p>
                  🆓 அனுமதி இலவசம் <br />
                  📅 திகதி: 20.07.2024 <br />
                  🕖 நேரம்: 7:00 PM to 09:00 PM <br />
                  📍 செயலி: Google Meet
                </p>
                <p>📌 விண்ணப்ப இறுதி திகதி: 19.07.2024</p>

                <p>
                  இந்த Webinar Online இல் நடைபெறும் அதற்குரிய இணைப்பு உங்களுக்கு
                  Email Or WhatsApp ஊடாக கிடைக்க பெறும்.
                </p>
                <p>
                  மேலதிக விபரங்களிற்கு 0707794432 எனும் எண்ணை தொடர்பு கொள்ளவும்.
                </p>
              </Box>
            </Card>
            <form onSubmit={newApplicant.handleSubmit}>
              <Box m={2}>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Full Name</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="name"
                    value={newApplicant.values.name}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.name &&
                      Boolean(newApplicant.errors.name)
                    }
                    helperText={
                      newApplicant.touched.name && newApplicant.errors.name
                    }
                  />
                </Card>

                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Phone Number</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="localNumber"
                    value={newApplicant.values.localNumber}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.localNumber &&
                      Boolean(newApplicant.errors.localNumber)
                    }
                    helperText={
                      newApplicant.touched.localNumber &&
                      newApplicant.errors.localNumber
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">WhatsApp Number</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="whatsAppNumber"
                    value={newApplicant.values.whatsAppNumber}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.whatsAppNumber &&
                      Boolean(newApplicant.errors.whatsAppNumber)
                    }
                    helperText={
                      newApplicant.touched.whatsAppNumber &&
                      newApplicant.errors.whatsAppNumber
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Email Address</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="email"
                    value={newApplicant.values.email}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.email &&
                      Boolean(newApplicant.errors.email)
                    }
                    helperText={
                      newApplicant.touched.email && newApplicant.errors.email
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Your Current Status
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.status &&
                      Boolean(newApplicant.errors.status)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="status"
                      value={newApplicant.values.status}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="schoolStudent"
                        control={<Radio />}
                        label="School Student"
                      />
                      <FormControlLabel
                        value="olCompleted"
                        control={<Radio />}
                        label="O/L Completed"
                      />
                      <FormControlLabel
                        value="alCompleted"
                        control={<Radio />}
                        label="A/L Completed"
                      />
                      <FormControlLabel
                        value="universityStudent"
                        control={<Radio />}
                        label="University Student"
                      />
                      <FormControlLabel
                        value="working"
                        control={<Radio />}
                        label="Working"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.status &&
                        newApplicant.errors.status}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">
                    Name of your School/University/Working place
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="professionName"
                    value={newApplicant.values.professionName}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.professionName &&
                      Boolean(newApplicant.errors.professionName)
                    }
                    helperText={
                      newApplicant.touched.professionName &&
                      newApplicant.errors.professionName
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Gender
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.gender &&
                      Boolean(newApplicant.errors.gender)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="gender"
                      value={newApplicant.values.gender}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.gender &&
                        newApplicant.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Do you know HTML and CSS
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.knowHtml &&
                      Boolean(newApplicant.errors.knowHtml)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="knowHtml"
                      value={newApplicant.values.knowHtml}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.knowHtml &&
                        newApplicant.errors.knowHtml}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Your District
                  </Typography>
                  <FormControl
                    fullWidth
                    error={
                      newApplicant.touched.district &&
                      Boolean(newApplicant.errors.district)
                    }
                  >
                    <InputLabel>Select District</InputLabel>
                    <Select
                      label="Select District"
                      name="district"
                      value={newApplicant.values.district}
                      onChange={newApplicant.handleChange}
                      onBlur={newApplicant.handleBlur}
                      sx={{ height: "45px" }}
                    >
                      {districts.map((district) => (
                        <MenuItem key={district} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {newApplicant.touched.district &&
                        newApplicant.errors.district}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Select your age range
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.ageLimit &&
                      Boolean(newApplicant.errors.ageLimit)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="ageLimit"
                      value={newApplicant.values.ageLimit}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="under18"
                        control={<Radio />}
                        label="Under 18"
                      />
                      <FormControlLabel
                        value="18to24"
                        control={<Radio />}
                        label="18 to 24"
                      />
                      <FormControlLabel
                        value="25to34"
                        control={<Radio />}
                        label="25 to 34"
                      />
                      <FormControlLabel
                        value="35to44"
                        control={<Radio />}
                        label="35 to 44"
                      />
                      <FormControlLabel
                        value="above45"
                        control={<Radio />}
                        label="Over 45"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.ageLimit &&
                        newApplicant.errors.ageLimit}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Do you have a laptop or PC?
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.laptop &&
                      Boolean(newApplicant.errors.laptop)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="laptop"
                      value={newApplicant.values.laptop}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.laptop &&
                        newApplicant.errors.laptop}
                    </FormHelperText>
                  </FormControl>
                </Card>

                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    How do you know about this Webminar?
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.gotToKnowFrom &&
                      Boolean(newApplicant.errors.gotToKnowFrom)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="gotToKnowFrom"
                      value={newApplicant.values.gotToKnowFrom}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="faceBook"
                        control={<Radio />}
                        label="FaceBook"
                      />
                      <FormControlLabel
                        value="instagram"
                        control={<Radio />}
                        label="Instagram"
                      />
                      <FormControlLabel
                        value="whatsApp"
                        control={<Radio />}
                        label="WhatsApp"
                      />
                      <FormControlLabel
                        value="fromFriend"
                        control={<Radio />}
                        label="From friend"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.gotToKnowFrom &&
                        newApplicant.errors.gotToKnowFrom}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Box sx={{ mt: 2 }}>
                  {Object.keys(newApplicant.errors).length > 0 && (
                    <div
                      style={{
                        padding: "5px",
                        border: "1px solid #FCEBE7",
                        borderRadius: "5px",
                        backgroundColor: "#FCF5F4",
                      }}
                    >
                      <>Please fill every filed.</>
                    </div>
                  )}
                  <br />
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        ) : (
          <>
            <Card
              sx={{
                p: 4,
                mb: 3,
                borderRadius: "15px",
                height: "auto",
                backgroundColor: "#f5f5f5",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography variant="h4" sx={{ mb: 2, color: "#1976d2" }}>
                Your Form Submitted Successfully
              </Typography>
              <Typography variant="body1" sx={{ color: "#555" }}>
                இந்த Webinar தொடர்பான விடயங்கள் மற்றும் இந்த Webinar இல் இணைந்து
                கொள்வதற்கான Google meet Link இனை பெற்றுக்கொள்வதற்கும் கீழே உள்ள
                WhatsApp Group invite Link இனை கிளிக் செய்து TrendsED இன்
                Official WhatsApp Group இல் இணைந்து கொள்ளுங்கள். <br /> Follow
                this link to join TrendsED React WhatsApp group: <br />
                <a
                  href="https://chat.whatsapp.com/BesFOzxw8ZiABitfStuF2C"
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  https://chat.whatsapp.com/BesFOzxw8ZiABitfStuF2C
                </a>
              </Typography>
              <br />
              <br />
              <br />
              <a
                style={{ textDecoration: "underline", color: "blue" }}
                onClick={() => setIsSubmitted(false)}
              >
                Submit another response
              </a>
            </Card>
          </>
        )}
      </Box>
    </Box>
  );
}
