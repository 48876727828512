import React, { useState, useEffect } from "react";
import { supabase } from "../configurations/supabaseConfig";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import { CSVLink } from "react-csv";

export default function ParticipantsList() {
  const [participants, setParticipants] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [currentCourse, setCurrentCourse] = useState(
    "digital_marketing_registrations"
  );

  useEffect(() => {
    fetchParticipants(page, rowsPerPage, currentCourse);
  }, [page, rowsPerPage, currentCourse]);

  const fetchParticipants = async (page, rowsPerPage, course) => {
    const { data, error, count } = await supabase
      .from(course)
      .select("*", { count: "exact" })
      .order("id", { ascending: false })
      .range(page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1);

    if (error) {
      console.error("Error fetching participants:", error);
      setParticipants([]);
    } else {
      setParticipants(data);
      setTotalRows(count);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCourseChange = (course) => {
    setCurrentCourse(course);
    setPage(0);
  };

  const headers = [
    { label: "Name", key: "full_name" },
    { label: "WhatsApp Number", key: "whatsapp_number" },
    { label: "Local Number", key: "phone_number" },
    { label: "Email", key: "email_address" },
    { label: "institution_name", key: "institution_name" },
    { label: "interested_in_course", key: "interested_in_course" },
    { label: "Gender", key: "gender" },
    { label: "experience_level", key: "experience_level" },
    { label: "current_status", key: "current_status" },
    { label: "District", key: "district" },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Card sx={{ p: 2, mb: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Registered Participants <small>Total : {totalRows}</small>
        </Typography>
        <Box sx={{ mb: 2 }}>
          {/* <Button
            variant="contained"
            color={
              currentCourse === "react_course_b1" ? "primary" : "secondary"
            }
            onClick={() => handleCourseChange("react_course_b1")}
            sx={{ mr: 2 }}
          >
            React Course
          </Button> */}
          {/* <Button
            variant="contained"
            color={
              currentCourse === "html_series_applications"
                ? "primary"
                : "secondary"
            }
            onClick={() => handleCourseChange("html_series_applications")}
            sx={{ mr: 2 }}
          >
            HTML Webinar Series
          </Button> */}
          <Button
            variant="contained"
            color={
              currentCourse === "html_series_applications"
                ? "primary"
                : "secondary"
            }
            onClick={() =>
              handleCourseChange("digital_marketing_registrations")
            }
            sx={{ mr: 2 }}
          >
            Digital Marketing Result
          </Button>
          {/* <Button
            variant="contained"
            color={currentCourse === "python-course" ? "primary" : "secondary"}
            onClick={() => handleCourseChange("python-course")}
          >
            Python Course
          </Button> */}
        </Box>
        {participants.length ? (
          <>
            <CSVLink
              data={participants}
              headers={headers}
              filename={`${currentCourse}-participants.csv`}
            >
              <Button variant="contained" color="primary">
                Download CSV
              </Button>
            </CSVLink>
            <TableContainer sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableCell key={header.key}>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {participants.map((participant) => (
                    <TableRow key={participant.id}>
                      {headers.map((header) => (
                        <TableCell key={header.key}>
                          {participant[header.key]
                            ? participant[header.key]
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={totalRows}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>No participants </>
        )}
      </Card>
    </Box>
  );
}
