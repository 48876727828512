import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RegistrationForm from "./pages/registrationForm";
import CourseForm from "./pages/courseForm";
import ParticipantsList from "./pages/paticipantsList";
import WhatsAppButton from "./component/whatsAppButton";
import HomePage from "./pages/homPage";
import ReactFormB1 from "./pages/reactFormB1";
import DigitalMarketing from "./pages/digitalMarketing";
import HtmlSeries from "./pages/htmlSeries";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<RegistrationForm />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/react-form" element={<ReactFormB1 />} />
        <Route path="/digital-marketing-form" element={<DigitalMarketing />} />
        <Route path="/series-form" element={<HtmlSeries />} />
        <Route path="/python-form" element={<CourseForm />} />
        <Route path="/participants" element={<ParticipantsList />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <WhatsAppButton />
    </Router>
  );
}

export default App;
