import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { supabase } from "../configurations/supabaseConfig";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export default function HtmlSeries() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    try {
      // Convert all keys to lowercase
      const lowercaseValues = Object.keys(values).reduce((acc, key) => {
        acc[key.toLowerCase()] = values[key];
        return acc;
      }, {});

      await addApplicantData(lowercaseValues);
      resetForm();
      setIsSubmitted(true);
      toast.success("Form has been successfully submitted!");
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const newApplicant = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      whatsAppNumber: "",
      email: "",
      status: "",
      professionName: "",
      gender: "",
      district: "",
      laptop: "",
      attendanceDays: [],
      advancedTopicsInterest: "",
      advancedTopics: [],
      gotToKnowFrom: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      phoneNumber: Yup.string().required("Required"),
      whatsAppNumber: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      status: Yup.string().required("Required"),
      professionName: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      district: Yup.string().required("Required"),
      laptop: Yup.string().required("Required"),
      attendanceDays: Yup.array()
        .min(1, "Select at least one day")
        .required("Required"),
      advancedTopicsInterest: Yup.string().required("Required"),
      gotToKnowFrom: Yup.string().required("Required"),
    }),

    validate: (values) => {
      const errors = {};
      if (
        values.advancedTopicsInterest === "yes" &&
        values.advancedTopics.length === 0
      ) {
        errors.advancedTopics = "Select at least one advanced topic";
      }
      return errors;
    },

    onSubmit,
  });
  const addApplicantData = async (values) => {
    try {
      const { data, error } = await supabase
        .from("html_series_applications")
        .insert([values])
        .select("*");
      if (data) {
        console.log("Data added successfully:", data);
        toast.info("Applicant has been successfully created!");
      }
      if (error) {
        throw error;
      }
    } catch (error) {
      throw new Error("Error inserting data:", error.message);
    }
  };

  const districts = [
    "Ampara",
    "Anuradhapura",
    "Badulla",
    "Batticaloa",
    "Colombo",
    "Galle",
    "Gampaha",
    "Hambantota",
    "Jaffna",
    "Kalutara",
    "Kandy",
    "Kegalle",
    "Kilinochchi",
    "Kurunegala",
    "Mannar",
    "Matale",
    "Matara",
    "Monaragala",
    "Mullaitivu",
    "Nuwara Eliya",
    "Polonnaruwa",
    "Puttalam",
    "Ratnapura",
    "Trincomalee",
    "Vavuniya",
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mb: 2,
          width: "60%",
          mt: 2,
          "@media (max-width: 600px)": {
            width: "90%",
          },
        }}
      >
        {!isSubmitted ? (
          <>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                mb: 1,
                borderRadius: "10px",
              }}
            >
              <img
                src="/images/courseImage/htmlseriesbanner.png"
                alt="header image"
                className="responsive-image"
                style={{ width: "100%" }}
              />

              <Box m={2}>
                <h3>HTML,CSS & JAVA SCRIPT</h3>
                <p>
                  🌟 03 நாட்களில் Website ஒன்றை புதிதாய் Create செய்ய அறிந்து
                  கொள்ளுங்கள்! 🚀
                  <br />
                </p>
                <p>
                  🖥️ HTML, CSS & JavaScript இன் அடிப்படை முதல் Advance வரை
                  கற்றுக்கொள்ள! 📚
                </p>
                <p>
                  💯 முற்றிலும் இலவசமாக TrendsEd Institute இனால் எதிர்வரும்
                  August மாத இறுதியில் நடைபெறவுள்ள Online training series இல்
                  இணைந்திடுங்கள். 🎓
                </p>
                <p>
                  📅 திகதி: 30/08, 31/08 & 01/09 <br />
                  ⏰ நேரம்: 7:00 PM to 09:00 PM <br />
                  📍 செயலி: Google Meet <br />⏳ விண்ணப்ப இறுதி திகதி:
                  29.08.2024
                </p>
                <p>
                  <strong>🆓 அனுமதி இலவசம்</strong> <br />
                  <br />
                  இந்த Online training series இல் Join பண்ணுவதற்குரிய Meeting
                  link உங்களுக்கு Email Or WhatsApp ஊடாக கிடைக்க பெறும்.
                  <br />
                  மேலதிக விபரங்களிற்கு 0707794432 எனும் எண்ணை தொடர்பு கொள்ளவும்.
                </p>
              </Box>
            </Card>
            <form onSubmit={newApplicant.handleSubmit}>
              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6">Full Name</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  name="name"
                  value={newApplicant.values.name}
                  onChange={newApplicant.handleChange}
                  error={
                    newApplicant.touched.name &&
                    Boolean(newApplicant.errors.name)
                  }
                  helperText={
                    newApplicant.touched.name && newApplicant.errors.name
                  }
                />
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6">Phone Number</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  name="phoneNumber"
                  value={newApplicant.values.phoneNumber}
                  onChange={newApplicant.handleChange}
                  error={
                    newApplicant.touched.phoneNumber &&
                    Boolean(newApplicant.errors.phoneNumber)
                  }
                  helperText={
                    newApplicant.touched.phoneNumber &&
                    newApplicant.errors.phoneNumber
                  }
                />
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6">WhatsApp Number</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  name="whatsAppNumber"
                  value={newApplicant.values.whatsAppNumber}
                  onChange={newApplicant.handleChange}
                  error={
                    newApplicant.touched.whatsAppNumber &&
                    Boolean(newApplicant.errors.whatsAppNumber)
                  }
                  helperText={
                    newApplicant.touched.whatsAppNumber &&
                    newApplicant.errors.whatsAppNumber
                  }
                />
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6">Email Address</Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  name="email"
                  value={newApplicant.values.email}
                  onChange={newApplicant.handleChange}
                  error={
                    newApplicant.touched.email &&
                    Boolean(newApplicant.errors.email)
                  }
                  helperText={
                    newApplicant.touched.email && newApplicant.errors.email
                  }
                />
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  Your Current Status
                </Typography>
                <FormControl
                  error={
                    newApplicant.touched.status &&
                    Boolean(newApplicant.errors.status)
                  }
                >
                  <RadioGroup
                    name="status"
                    value={newApplicant.values.status}
                    onChange={newApplicant.handleChange}
                  >
                    <FormControlLabel
                      value="schoolStudent"
                      control={<Radio />}
                      label="School Student"
                    />
                    <FormControlLabel
                      value="olCompleted"
                      control={<Radio />}
                      label="O/L Completed"
                    />
                    <FormControlLabel
                      value="alCompleted"
                      control={<Radio />}
                      label="A/L Completed"
                    />
                    <FormControlLabel
                      value="universityStudent"
                      control={<Radio />}
                      label="University Student"
                    />
                    <FormControlLabel
                      value="working"
                      control={<Radio />}
                      label="Working"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {newApplicant.touched.status && newApplicant.errors.status}
                  </FormHelperText>
                </FormControl>
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6">
                  Name of your School/University/Working place
                </Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  name="professionName"
                  value={newApplicant.values.professionName}
                  onChange={newApplicant.handleChange}
                  error={
                    newApplicant.touched.professionName &&
                    Boolean(newApplicant.errors.professionName)
                  }
                  helperText={
                    newApplicant.touched.professionName &&
                    newApplicant.errors.professionName
                  }
                />
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  Gender
                </Typography>
                <FormControl
                  error={
                    newApplicant.touched.gender &&
                    Boolean(newApplicant.errors.gender)
                  }
                >
                  <RadioGroup
                    name="gender"
                    value={newApplicant.values.gender}
                    onChange={newApplicant.handleChange}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {newApplicant.touched.gender && newApplicant.errors.gender}
                  </FormHelperText>
                </FormControl>
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  Your District
                </Typography>
                <FormControl
                  fullWidth
                  error={
                    newApplicant.touched.district &&
                    Boolean(newApplicant.errors.district)
                  }
                >
                  <InputLabel>Select District</InputLabel>
                  <Select
                    label="Select District"
                    name="district"
                    value={newApplicant.values.district}
                    onChange={newApplicant.handleChange}
                  >
                    {districts.map((district) => (
                      <MenuItem key={district} value={district}>
                        {district}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {newApplicant.touched.district &&
                      newApplicant.errors.district}
                  </FormHelperText>
                </FormControl>
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  Do you have access to a laptop for the duration of the
                  Webinar?
                </Typography>
                <FormControl
                  error={
                    newApplicant.touched.laptop &&
                    Boolean(newApplicant.errors.laptop)
                  }
                >
                  <RadioGroup
                    name="laptop"
                    value={newApplicant.values.laptop}
                    onChange={newApplicant.handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {newApplicant.touched.laptop && newApplicant.errors.laptop}
                  </FormHelperText>
                </FormControl>
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  Which days of the series will you attend?
                </Typography>
                <FormControl
                  error={
                    newApplicant.touched.attendanceDays &&
                    Boolean(newApplicant.errors.attendanceDays)
                  }
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newApplicant.values.attendanceDays.includes(
                          "html"
                        )}
                        onChange={() => {
                          const newAttendanceDays =
                            newApplicant.values.attendanceDays.includes("html")
                              ? newApplicant.values.attendanceDays.filter(
                                  (day) => day !== "html"
                                )
                              : [...newApplicant.values.attendanceDays, "html"];
                          newApplicant.setFieldValue(
                            "attendanceDays",
                            newAttendanceDays
                          );
                        }}
                      />
                    }
                    label="Day 01 - HTML"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newApplicant.values.attendanceDays.includes(
                          "css"
                        )}
                        onChange={() => {
                          const newAttendanceDays =
                            newApplicant.values.attendanceDays.includes("css")
                              ? newApplicant.values.attendanceDays.filter(
                                  (day) => day !== "css"
                                )
                              : [...newApplicant.values.attendanceDays, "css"];
                          newApplicant.setFieldValue(
                            "attendanceDays",
                            newAttendanceDays
                          );
                        }}
                      />
                    }
                    label="Day 02 - CSS"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newApplicant.values.attendanceDays.includes(
                          "javascript"
                        )}
                        onChange={() => {
                          const newAttendanceDays =
                            newApplicant.values.attendanceDays.includes(
                              "javascript"
                            )
                              ? newApplicant.values.attendanceDays.filter(
                                  (day) => day !== "javascript"
                                )
                              : [
                                  ...newApplicant.values.attendanceDays,
                                  "javascript",
                                ];
                          newApplicant.setFieldValue(
                            "attendanceDays",
                            newAttendanceDays
                          );
                        }}
                      />
                    }
                    label="Day 03 - JavaScript"
                  />
                  <FormHelperText>
                    {newApplicant.touched.attendanceDays &&
                      newApplicant.errors.attendanceDays}
                  </FormHelperText>
                </FormControl>
              </Card>

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  After completing the HTML, CSS, and JavaScript series, are you
                  interested in studying more advanced topics?
                </Typography>
                <FormControl
                  error={
                    newApplicant.touched.advancedTopicsInterest &&
                    Boolean(newApplicant.errors.advancedTopicsInterest)
                  }
                >
                  <RadioGroup
                    name="advancedTopicsInterest"
                    value={newApplicant.values.advancedTopicsInterest}
                    onChange={newApplicant.handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes, I'm interested in learning more"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No, I'll stick with HTML, CSS, and JavaScript for now"
                    />
                    <FormControlLabel
                      value="notSure"
                      control={<Radio />}
                      label="I'm not sure yet"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {newApplicant.touched.advancedTopicsInterest &&
                      newApplicant.errors.advancedTopicsInterest}
                  </FormHelperText>
                </FormControl>
              </Card>

              {newApplicant.values.advancedTopicsInterest === "yes" && (
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    If yes, which topics interest you?
                  </Typography>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newApplicant.values.advancedTopics.includes(
                            "react"
                          )}
                          onChange={() => {
                            const newAdvancedTopics =
                              newApplicant.values.advancedTopics.includes(
                                "react"
                              )
                                ? newApplicant.values.advancedTopics.filter(
                                    (topic) => topic !== "react"
                                  )
                                : [
                                    ...newApplicant.values.advancedTopics,
                                    "react",
                                  ];
                            newApplicant.setFieldValue(
                              "advancedTopics",
                              newAdvancedTopics
                            );
                          }}
                        />
                      }
                      label="React"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newApplicant.values.advancedTopics.includes(
                            "angular"
                          )}
                          onChange={() => {
                            const newAdvancedTopics =
                              newApplicant.values.advancedTopics.includes(
                                "angular"
                              )
                                ? newApplicant.values.advancedTopics.filter(
                                    (topic) => topic !== "angular"
                                  )
                                : [
                                    ...newApplicant.values.advancedTopics,
                                    "angular",
                                  ];
                            newApplicant.setFieldValue(
                              "advancedTopics",
                              newAdvancedTopics
                            );
                          }}
                        />
                      }
                      label="Angular"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newApplicant.values.advancedTopics.includes(
                            "nodejs"
                          )}
                          onChange={() => {
                            const newAdvancedTopics =
                              newApplicant.values.advancedTopics.includes(
                                "nodejs"
                              )
                                ? newApplicant.values.advancedTopics.filter(
                                    (topic) => topic !== "nodejs"
                                  )
                                : [
                                    ...newApplicant.values.advancedTopics,
                                    "nodejs",
                                  ];
                            newApplicant.setFieldValue(
                              "advancedTopics",
                              newAdvancedTopics
                            );
                          }}
                        />
                      }
                      label="NodeJS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newApplicant.values.advancedTopics.includes(
                            "python"
                          )}
                          onChange={() => {
                            const newAdvancedTopics =
                              newApplicant.values.advancedTopics.includes(
                                "python"
                              )
                                ? newApplicant.values.advancedTopics.filter(
                                    (topic) => topic !== "python"
                                  )
                                : [
                                    ...newApplicant.values.advancedTopics,
                                    "python",
                                  ];
                            newApplicant.setFieldValue(
                              "advancedTopics",
                              newAdvancedTopics
                            );
                          }}
                        />
                      }
                      label="Python"
                    />
                  </FormControl>
                </Card>
              )}

              <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                <Typography variant="h6" mb={2}>
                  How do you know about this Seminar?
                </Typography>
                <FormControl
                  error={
                    newApplicant.touched.gotToKnowFrom &&
                    Boolean(newApplicant.errors.gotToKnowFrom)
                  }
                >
                  <RadioGroup
                    name="gotToKnowFrom"
                    value={newApplicant.values.gotToKnowFrom}
                    onChange={newApplicant.handleChange}
                  >
                    <FormControlLabel
                      value="facebook"
                      control={<Radio />}
                      label="FaceBook"
                    />
                    <FormControlLabel
                      value="instagram"
                      control={<Radio />}
                      label="Instagram"
                    />
                    <FormControlLabel
                      value="whatsapp"
                      control={<Radio />}
                      label="WhatsApp"
                    />
                    <FormControlLabel
                      value="fromFriend"
                      control={<Radio />}
                      label="From friend"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                  <FormHelperText>
                    {newApplicant.touched.gotToKnowFrom &&
                      newApplicant.errors.gotToKnowFrom}
                  </FormHelperText>
                </FormControl>
              </Card>

              <Box sx={{ mt: 2 }}>
                {Object.keys(newApplicant.errors).length > 0 && (
                  <div
                    style={{
                      padding: "5px",
                      border: "1px solid #FCEBE7",
                      borderRadius: "5px",
                      backgroundColor: "#FCF5F4",
                      marginBottom: "10px",
                    }}
                  >
                    Please fill in all required fields.
                  </div>
                )}
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                  <strong>
                    Thank you for registering for our webinar series! 🎉
                  </strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We're thrilled to have you on board. 🚀 Our team will be in
                  touch shortly with all the necessary information to get you
                  started. We understand you may have questions as you prepare,
                  and we're here to help. 💡
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" gutterBottom>
                  If anything comes to mind, please don't hesitate to reach out
                  to us at <strong>+94 76 479 7613</strong>. 📞 We're committed
                  to ensuring you have a smooth and enriching experience from
                  the very beginning. 🌟
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" gutterBottom>
                  Your enthusiasm for learning is what drives us, and we can't
                  wait to embark on this educational journey with you.
                </Typography>
                <Typography variant="h6" component="p">
                  <strong>
                    Welcome aboard, and here's to your success! 🎓🏆
                  </strong>
                </Typography>
                <p
                  style={{ textDecoration: "underline", color: "blue" }}
                  className="text-center"
                  onClick={() => setIsSubmitted(false)}
                >
                  Submit another response
                </p>
              </Box>
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
}
