import React from 'react';

const WhatsAppButton = () => {
  const openWhatsApp = () => {
    window.open("https://wa.me/94707794432", "_blank");
  };

  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  };

  const imgStyle = {
    width: '35px',
    height: '35px',
  };

  return (
    <div style={buttonStyle} onClick={openWhatsApp}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp Logo"
        style={imgStyle}
      />
    </div>
  );
};

export default WhatsAppButton;
