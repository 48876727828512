import React from "react";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    console.log(route);
    navigate(route);
  };
  return (
    <>
      <div className="container">
        <h1 className="text-center m-4">Available Courses</h1>
        <div className="row justify-content-md-center mt-4">
          <div className="col-md-6 col-12 col-lg-4 d-flex p-2">
            <div
              className="card w-100 d-flex flex-column "
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigation("/react-form")}
            >
              <img
                src="./images/courseImage/reactB1.jpg"
                className="card-img-top"
                alt="React Course"
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">React for Web Development</h5>
                <div className="mt-auto">
                  <a href="#" className="btn btn-primary">
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4 d-flex p-2">
            <div
              className="card w-100 d-flex flex-column"
              onClick={() => handleNavigation("/")}
            >
              <img
                src="./images/courseImage/python2.jpg"
                className="card-img-top"
                alt="React Course"
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Fundamentals of Python Batch - 2</h5>
                <div className="mt-auto">
                  <a href="#" className="btn btn-primary disabled">
                    Opening Soon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-center m-4">Seminar Registrations</h1>
        <div className="row justify-content-md-center mt-4">
          <div className="col-md-6 col-12 col-lg-4 d-flex p-2">
            <div
              className="card w-100 d-flex flex-column "
              style={{ cursor: "pointer" }}
              onClick={() => handleNavigation("/series-form")}
            >
              <img
                src="./images/courseImage/htmlseries.jpg"
                className="card-img-top"
                alt="React Course"
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">React for Web Development</h5>
                <div className="mt-auto">
                  <a href="#" className="btn btn-primary">
                    Register Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
