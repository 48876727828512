import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { supabase } from "../configurations/supabaseConfig";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export default function ReactFormB1() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    try {
      await addApplicantData(values);
      resetForm();
      setIsSubmitted(true);
      toast.success("Form has been successfully submitted!");
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const newApplicant = useFormik({
    initialValues: {
      name: "",
      promo: "",
      interest: "",
      whatsAppNumber: "",
      localNumber: "",
      email: "",
      professionName: "",
      status: "",
      laptop: "",
      gender: "",
      htmlRating: "",
      cssRating: "",
      jsRating: "",
      gotToKnowFrom: "",
      ageLimit: "",
      district: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      promo: Yup.string(),
      interest: Yup.string(),
      professionName: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      htmlRating: Yup.string().required("Required"),
      cssRating: Yup.string().required("Required"),
      jsRating: Yup.string().required("Required"),
      gotToKnowFrom: Yup.string().required("Required"),
      ageLimit: Yup.string().required("Required"),
      laptop: Yup.string().required("Required"),
      district: Yup.string().required("Required"),
      localNumber: Yup.string().required("Required"),
      whatsAppNumber: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit,
  });

  const addApplicantData = async (values) => {
    try {
      const { data, error } = await supabase
        .from("react_course_b1")
        .insert([values])
        .select("*");
      if (data) {
        console.log("Data added successfully:", data);
        toast.info("Applicant has been successfully created!");
      }
      if (error) {
        throw error;
      }
    } catch (error) {
      throw new Error("Error inserting data:", error.message);
    }
  };

  const districts = [
    "Ampara",
    "Anuradhapura",
    "Badulla",
    "Batticaloa",
    "Colombo",
    "Galle",
    "Gampaha",
    "Hambantota",
    "Jaffna",
    "Kalutara",
    "Kandy",
    "Kegalle",
    "Kilinochchi",
    "Kurunegala",
    "Mannar",
    "Matale",
    "Matara",
    "Monaragala",
    "Mullaitivu",
    "Nuwara Eliya",
    "Polonnaruwa",
    "Puttalam",
    "Ratnapura",
    "Trincomalee",
    "Vavuniya",
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mb: 2,
          width: "60%",
          mt: 2,
          "@media (max-width: 600px)": {
            width: "90%",
          },
        }}
      >
        {!isSubmitted ? (
          <>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                m: 2,
                mb: 1,
                borderRadius: "10px",
              }}
            >
              <img
                src="/images/courseImage/reactBannerCourseB1.jpg"
                alt="header image"
                className="responsive-image"
                style={{ width: "100%" }}
              />

              <Box m={2}>
                <h3>React for web development</h3>
                <p>
                  Thank you for choosing TrendsEd Academy for your React
                  learning journey! Please fill out the following Registration
                  form to secure your spot in our upcoming React course.
                </p>
                <p>
                  உங்கள் React கற்றல் பயணத்திற்காக TrendsEd Academy ஐ
                  தேர்ந்தெடுத்ததற்கு நன்றி! இந்த <strong>Form</strong> ஐ
                  நிரப்புவதன் ஊடாக எமது React Course இற்கு உடனே
                  விண்ணப்பியுங்கள்.
                </p>
                <p>
                  <strong>Course Details:</strong> <br />
                  Duration: 3 months. <br />
                  Payment: Full payment of <strong>18000 LKR</strong> for the
                  3-month course. Registration Fees <strong>1000 LKR</strong>{" "}
                  <br />
                  Certificate: E certificate will be provided with URL <br />
                </p>
                <p>
                  <strong>Courses Highlights:</strong>
                </p>
                <ul>
                  <li>Learn from industry experts</li>
                  <li>From Basics to Essential Concepts for Web Development</li>
                  <li>Immersive React Course</li>
                  <li>Shareable online certification</li>
                  <li>Assistance in creating a standout portfolio</li>
                </ul>
                <p>
                  {" "}
                  <strong>Payment Details:</strong>{" "}
                </p>
                <p>
                  Registration Fees 1000LKR <br />
                  Payment Plan for the Course: 2 parts - 9000 LKR before the
                  first class, and the remaining 9000 LKR with in 2 week.
                </p>
                <p>
                  {" "}
                  <strong>Admission Closing Date: 08.08.2024 </strong>
                </p>
                <p>
                  மேலதிக விபரங்களிற்கு 0764797613 எனும் எண்ணை தொடர்பு கொள்ளவும்.
                </p>
              </Box>
            </Card>
            <form onSubmit={newApplicant.handleSubmit}>
              <Box m={2}>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Full Name</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="name"
                    value={newApplicant.values.name}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.name &&
                      Boolean(newApplicant.errors.name)
                    }
                    helperText={
                      newApplicant.touched.name && newApplicant.errors.name
                    }
                  />
                </Card>

                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Phone Number</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="localNumber"
                    value={newApplicant.values.localNumber}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.localNumber &&
                      Boolean(newApplicant.errors.localNumber)
                    }
                    helperText={
                      newApplicant.touched.localNumber &&
                      newApplicant.errors.localNumber
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">WhatsApp Number</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="whatsAppNumber"
                    value={newApplicant.values.whatsAppNumber}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.whatsAppNumber &&
                      Boolean(newApplicant.errors.whatsAppNumber)
                    }
                    helperText={
                      newApplicant.touched.whatsAppNumber &&
                      newApplicant.errors.whatsAppNumber
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Email Address</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="email"
                    value={newApplicant.values.email}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.email &&
                      Boolean(newApplicant.errors.email)
                    }
                    helperText={
                      newApplicant.touched.email && newApplicant.errors.email
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Your Current Status
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.status &&
                      Boolean(newApplicant.errors.status)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="status"
                      value={newApplicant.values.status}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="schoolStudent"
                        control={<Radio />}
                        label="School Student"
                      />
                      <FormControlLabel
                        value="olCompleted"
                        control={<Radio />}
                        label="O/L Completed"
                      />
                      <FormControlLabel
                        value="alCompleted"
                        control={<Radio />}
                        label="A/L Completed"
                      />
                      <FormControlLabel
                        value="universityStudent"
                        control={<Radio />}
                        label="University Student"
                      />
                      <FormControlLabel
                        value="working"
                        control={<Radio />}
                        label="Working"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.status &&
                        newApplicant.errors.status}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">
                    Name of your School/University/Working place
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="professionName"
                    value={newApplicant.values.professionName}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.professionName &&
                      Boolean(newApplicant.errors.professionName)
                    }
                    helperText={
                      newApplicant.touched.professionName &&
                      newApplicant.errors.professionName
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Gender
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.gender &&
                      Boolean(newApplicant.errors.gender)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="gender"
                      value={newApplicant.values.gender}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.gender &&
                        newApplicant.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    How would you rate your level of knowledge in HTML?
                  </Typography>
                  <div className="d-flex justify-content-center ">
                    <FormControl
                      error={
                        newApplicant.touched.htmlRating &&
                        Boolean(newApplicant.errors.htmlRating)
                      }
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="htmlRating"
                        value={newApplicant.values.htmlRating}
                        onChange={newApplicant.handleChange}
                      >
                        <div className="d-flex justify-content-center w-100">
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="2"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="4"
                          />
                          <FormControlLabel
                            value="5"
                            control={<Radio />}
                            label="5"
                          />
                        </div>
                      </RadioGroup>
                      <FormHelperText>
                        {newApplicant.touched.htmlRating &&
                          newApplicant.errors.htmlRating}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    How would you rate your level of knowledge in CSS?
                  </Typography>
                  <div className="d-flex justify-content-center ">
                    <FormControl
                      error={
                        newApplicant.touched.cssRating &&
                        Boolean(newApplicant.errors.cssRating)
                      }
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="cssRating"
                        value={newApplicant.values.cssRating}
                        onChange={newApplicant.handleChange}
                      >
                        <div className="d-flex justify-content-center w-100">
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="2"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="4"
                          />
                          <FormControlLabel
                            value="5"
                            control={<Radio />}
                            label="5"
                          />
                        </div>
                      </RadioGroup>
                      <FormHelperText>
                        {newApplicant.touched.cssRating &&
                          newApplicant.errors.cssRating}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    How would you rate your level of knowledge in JS?
                  </Typography>
                  <div className="d-flex justify-content-center ">
                    <FormControl
                      error={
                        newApplicant.touched.jsRating &&
                        Boolean(newApplicant.errors.jsRating)
                      }
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="jsRating"
                        value={newApplicant.values.jsRating}
                        onChange={newApplicant.handleChange}
                      >
                        <div className="d-flex justify-content-center w-100">
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="1"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="2"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="4"
                          />
                          <FormControlLabel
                            value="5"
                            control={<Radio />}
                            label="5"
                          />
                        </div>
                      </RadioGroup>
                      <FormHelperText>
                        {newApplicant.touched.jsRating &&
                          newApplicant.errors.jsRating}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Your District
                  </Typography>
                  <FormControl
                    fullWidth
                    error={
                      newApplicant.touched.district &&
                      Boolean(newApplicant.errors.district)
                    }
                  >
                    <InputLabel>Select District</InputLabel>
                    <Select
                      label="Select District"
                      name="district"
                      value={newApplicant.values.district}
                      onChange={newApplicant.handleChange}
                      onBlur={newApplicant.handleBlur}
                      sx={{ height: "45px" }}
                    >
                      {districts.map((district) => (
                        <MenuItem key={district} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {newApplicant.touched.district &&
                        newApplicant.errors.district}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Select your age range
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.ageLimit &&
                      Boolean(newApplicant.errors.ageLimit)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="ageLimit"
                      value={newApplicant.values.ageLimit}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="under18"
                        control={<Radio />}
                        label="Under 18"
                      />
                      <FormControlLabel
                        value="18to24"
                        control={<Radio />}
                        label="18 to 24"
                      />
                      <FormControlLabel
                        value="25to34"
                        control={<Radio />}
                        label="25 to 34"
                      />
                      <FormControlLabel
                        value="35to44"
                        control={<Radio />}
                        label="35 to 44"
                      />
                      <FormControlLabel
                        value="above45"
                        control={<Radio />}
                        label="Over 45"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.ageLimit &&
                        newApplicant.errors.ageLimit}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">Promo Code (Optional)</Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="promo"
                    value={newApplicant.values.promo}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.promo &&
                      Boolean(newApplicant.errors.promo)
                    }
                    helperText={
                      newApplicant.touched.promo && newApplicant.errors.promo
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6">
                    Why are you interested in learning React?
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="standard-basic"
                    label="Answer Here"
                    name="interest"
                    value={newApplicant.values.interest}
                    onChange={newApplicant.handleChange}
                    onBlur={newApplicant.handleBlur}
                    error={
                      newApplicant.touched.interest &&
                      Boolean(newApplicant.errors.interest)
                    }
                    helperText={
                      newApplicant.touched.interest &&
                      newApplicant.errors.interest
                    }
                  />
                </Card>
                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    Do you have a laptop or PC?
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.laptop &&
                      Boolean(newApplicant.errors.laptop)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="laptop"
                      value={newApplicant.values.laptop}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.laptop &&
                        newApplicant.errors.laptop}
                    </FormHelperText>
                  </FormControl>
                </Card>

                <Card sx={{ p: 3, mb: 2, borderRadius: "10px" }}>
                  <Typography variant="h6" mb={2}>
                    How do you know about this Course?
                  </Typography>
                  <FormControl
                    error={
                      newApplicant.touched.gotToKnowFrom &&
                      Boolean(newApplicant.errors.gotToKnowFrom)
                    }
                  >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="gotToKnowFrom"
                      value={newApplicant.values.gotToKnowFrom}
                      onChange={newApplicant.handleChange}
                    >
                      <FormControlLabel
                        value="faceBook"
                        control={<Radio />}
                        label="FaceBook"
                      />
                      <FormControlLabel
                        value="instagram"
                        control={<Radio />}
                        label="Instagram"
                      />
                      <FormControlLabel
                        value="whatsApp"
                        control={<Radio />}
                        label="WhatsApp"
                      />
                      <FormControlLabel
                        value="fromFriend"
                        control={<Radio />}
                        label="From friend"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {newApplicant.touched.gotToKnowFrom &&
                        newApplicant.errors.gotToKnowFrom}
                    </FormHelperText>
                  </FormControl>
                </Card>
                <Box sx={{ mt: 2 }}>
                  {Object.keys(newApplicant.errors).length > 0 && (
                    <div
                      style={{
                        padding: "5px",
                        border: "1px solid #FCEBE7",
                        borderRadius: "5px",
                        backgroundColor: "#FCF5F4",
                      }}
                    >
                      <>Please fill every filed.</>
                    </div>
                  )}
                  <br />
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        ) : (
          <>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                  <strong>Thank you for registering for our course! 🎉</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We're thrilled to have you on board. 🚀 Our team will be in
                  touch shortly with all the necessary information to get you
                  started. We understand you may have questions as you prepare,
                  and we're here to help. 💡
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" gutterBottom>
                  If anything comes to mind, please don't hesitate to reach out
                  to us at <strong>+94 76 479 7613</strong>. 📞 We're committed
                  to ensuring you have a smooth and enriching experience from
                  the very beginning. 🌟
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" gutterBottom>
                  Your enthusiasm for learning is what drives us, and we can't
                  wait to embark on this educational journey with you.
                </Typography>
                <Typography variant="h6" component="p">
                  <strong>
                    Welcome aboard, and here's to your success! 🎓🏆
                  </strong>
                </Typography>
                <p
                  style={{ textDecoration: "underline", color: "blue" }}
                  className="text-center"
                  onClick={() => setIsSubmitted(false)}
                >
                  Submit another response
                </p>
              </Box>
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
}
